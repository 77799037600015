<template>
    <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="validate">
        <v-layout wrap>
            <v-flex xs12>
                <v-text-field label="Código" v-model="item.code" :rules="[rules.required]" />
            </v-flex>
            <v-flex xs12>
                <v-autocomplete v-model="item.idUser" :rules="[rules.required]" :items="userList" item-value="id" item-text="email" label="Usuários" deletable-chips small-chips />
            </v-flex>
            <v-flex xs12>
                <v-autocomplete v-model="item.status" :items="status" :rules="[rules.required]" item-value="key" item-text="description" label="Status" class="my-0 py-0"
                    deletable-chips small-chips />
            </v-flex>
            <v-flex xs12>
                <v-autocomplete v-model="item.type" :items="types" :rules="[rules.required]" item-value="key" item-text="description" label="Tipo" class="my-0 py-0" deletable-chips
                    small-chips />
            </v-flex>
            <v-flex xs12>
                <v-btn :loading="lock" :disabled="!valid" color="success" @click="validate" style="width: 100%;">Salvar</v-btn>
            </v-flex>
        </v-layout>
    </v-form>
</template>

<script>
import { mask } from "vue-the-mask";
import rules from "@/helpers/rules";

export default {
    directives: { mask },

    props: {
        itemToEdit: {
            type: Object,
        },
        lock: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            rules,
            valid: true,
            userList: [],
            itemClean: {
                code: "",
                idUser: "",
                status: "",
            },
            item: { ...this.itemClean },
            status: [
                { key: "OK", description: "Em uso" },
                { key: "BLOCKED", description: "Bloqueado" },
                { key: "DELETED", description: "Deletado" },
            ],
            types: [
                { key: "CARD", description: "Cartão NFC" },
                { key: "CAR", description: "Carro" },
                { key: "OTHERS", description: "Outros" },
            ],
        };
    },

    mounted() {
        this.$http
            .get("api/v2/user")
            .then((result) => {
                this.userList = result;
            })
            .catch(() => {
                this.userList = [];
            });
    },

    watch: {
        itemToEdit: {
            immediate: true,
            handler(newVal) {
                if (this.$refs.form) {
                    this.$refs.form.reset();
                }

                if (newVal == null) {
                    this.item = { ...this.itemClean };
                } else {
                    this.item = { ...newVal };
                }
            },
        },
    },

    methods: {
        validate() {
            if (this.$refs.form.validate()) {
                this.$emit("save", this.item);
            }
        },
    },
};
</script>